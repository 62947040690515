/* React import */
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

/* Third party import */
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

/* Local import */

import { ReactComponent as InformationIcon } from "../../../../../assets/information-icon.svg";
import {
    homePowerList,
    companyPowerList,
} from "../../../../../static/constants";
import {
    CREATE_ADDRESS,
    UPDATE_ADDRESS,
} from "../../../../../services/graphql/fleet/mutations";
import Form from "../../../misc/Form";
import { ChargingPowerSquare } from "../utils";
import { CountrySelector } from "../../../CountrySelector";
import { CountryCode } from "../../../../../enums";
import { LabeledInput } from "../../../misc/LabeledInput";
import { ReactComponent as HomeActiveIcon } from "../../../../../assets/driver_settings/home.svg";
import { ReactComponent as WorkplaceIcon } from "../../../../../assets/driver_settings/workplace.svg";
import { ReactComponent as HomeIcon } from "../../../../../assets/driver_settings/home-active.svg";
import { ReactComponent as WorkplaceActiveIcon } from "../../../../../assets/driver_settings/workplace-active.svg";
import { useQuery as useRestQuery } from "react-query";
import { getDefaultFuelAndEnergyPricesForUser } from "../../../../../services/rest/paymentProcess";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { Location } from "../../../../../@types/settings";
import { Button } from "../../../misc/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../misc/Buttonv2/types";
import { EventBanner, EventState } from "../../../misc/EventBanner";
import { Tooltip } from "../../../../../electrify_frontend_common/components/Tooltip";
import ModalWrapper from "../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../electrify_frontend_common/components/ModalWrapper/Headers";

function DriverEnergyValues({
    locationType,
    energyPrice,
    setEnergyPrice,
    energyCo2,
    setEnergyCo2,
}: {
    locationType: "WORKPLACE" | "HOME";
    energyPrice?: number | null;
    setEnergyPrice: Dispatch<SetStateAction<number | null | undefined>>;
    energyCo2?: number | null;
    setEnergyCo2: Dispatch<SetStateAction<number | null | undefined>>;
}) {
    const { currencySymbol } = useLayout();
    const {t} = useTranslation("addresses");

    const { data: defaultValues } = useRestQuery<{
        homeChargingPrice: number;
        homeChargingCo2Emissions: number;
        workplaceChargingPrice: number;
        workplaceChargingCo2Emissions: number;
    }>("defaultPrices", getDefaultFuelAndEnergyPricesForUser, {
        onError: (error) => {
            console.log(">>>ERR: ", error);
        },
    });

    const defaultEnergyPrice = useMemo(() => {
        return locationType == "HOME"
            ? defaultValues?.homeChargingPrice
            : defaultValues?.workplaceChargingPrice;
    }, [defaultValues, locationType]);

    const defaultEnergyEmission = useMemo(() => {
        return locationType == "HOME"
            ? defaultValues?.homeChargingCo2Emissions
            : defaultValues?.workplaceChargingCo2Emissions;
    }, [defaultValues, locationType]);

    return (
        <div className="flex w-full col-span-2">
            <div className="mr-2">
                <div className=" text-sm  text-Grey-dark mb-2">
                    {t("addLocation.pricePerKwh")}
                </div>
                <LabeledInput
                    unitsLabel={`${currencySymbol} / kWh`}
                    defaultValue={defaultEnergyPrice || 0}
                    value={energyPrice}
                    setValue={setEnergyPrice}
                />
            </div>
            <div className="ml-2">
                <div className="text-sm text-Grey-dark mb-2"> {t("addLocation.co2Emissions")}</div>
                <LabeledInput
                    unitsLabel="g/ kWh"
                    defaultValue={defaultEnergyEmission || 0}
                    value={energyCo2}
                    setValue={setEnergyCo2}
                />
            </div>
        </div>
    );
}

function LocationTypeToggles({
    locationType,
    setLocationType,
}: {
    locationType: "HOME" | "WORKPLACE";
    setLocationType: (v: "HOME" | "WORKPLACE") => void;
}) {

    const { t } = useTranslation("addresses");

    const selectedStyle =
        "bg-Blueberry-dark-default text-white rounded px-16 py-4 flex items-center";
    const notSelectedStyle =
        "border border-Blueberry-dark-default rounded px-16 py-4 text-Blueberry-dark-default flex items-center";

    return (
        <>
            <label className="text-Grey-dark mb-2">{t("addLocation.locationType")}</label>
            <div className="flex mb-4 mt-2">
                <button
                    onClick={() => setLocationType("HOME")}
                    className={
                        locationType == "HOME"
                            ? `${selectedStyle} mr-4`
                            : `${notSelectedStyle} mr-4`
                    }
                >
                    {t("addLocation.homeLocation")}{" "}
                    {locationType == "HOME" ? (
                        <HomeActiveIcon className="ml-2" />
                    ) : (
                        <HomeIcon className="ml-2" />
                    )}
                </button>
                <button
                    onClick={() => setLocationType("WORKPLACE")}
                    className={
                        locationType == "WORKPLACE"
                            ? selectedStyle
                            : notSelectedStyle
                    }
                >
                    {t("addLocation.workplaceLocation")}{" "}
                    {locationType == "WORKPLACE" ? (
                        <WorkplaceActiveIcon className="ml-2" />
                    ) : (
                        <WorkplaceIcon className="ml-2" />
                    )}
                </button>
            </div>
        </>
    );
}

export default function LocationModal({
    isOpen,
    closeModal,
    locationData,
    locationType,
    setLocationType,
    vehicleId,
    isDriver,
    className,
}: {
    isOpen: boolean;
    closeModal: () => void;
    locationData?: Location;
    locationType: "HOME" | "WORKPLACE";
    setLocationType?: (v: "HOME" | "WORKPLACE") => void;
    vehicleId?: string;
    isDriver?: boolean;
    className?: string;
}) {
    let { __typename, id, ...cleanedAddress } =
        locationData || ({} as Location);
    const [address, setAddress] = useState<Location>(cleanedAddress);

    const [energyPrice, setEnergyPrice] = useState<number | null | undefined>(
        locationData?.energyPrice
    );
    const [energyCo2, setEnergyCo2] = useState<number | null | undefined>(
        locationData?.energyCo2
    );

    useEffect(() => {
        setAddress({ ...address, energyPrice, energyCo2 });
    }, [energyPrice, energyCo2]);

    const [displayFormErrors, setDisplayFormErrors] = useState(false);
    const { t, i18n } = useTranslation("addresses");

    const updateFormValidity = useCallback(() => {
        const validity = {} as any;
        validity["name"] = !!address.name;
        validity["street"] = !!address.street;
        validity["streetNr"] = !!address.streetNr;
        validity["zip"] = !!address.zip;
        validity["city"] = !!address.city;
        validity["country"] = !!address.country;
        return validity;
    }, [address]);

    const [formValidity, setFormValidity] = useState(updateFormValidity());

    const isFormValid = () => {
        let isValid = true;
        Object.entries(formValidity).forEach(([k, v]) => {
            if (!v) isValid = false;
        });
        return isValid;
    };

    useEffect(() => {
        setFormValidity(updateFormValidity());
    }, [updateFormValidity]);

    const languageCode = i18n.language;
    const regionName = new Intl.DisplayNames([languageCode], {
        type: "region",
    });
    const { localeCode } = useLayout();

    const countryList = Object.values(CountryCode)
        .map((countryCode) => {
            return {
                value: countryCode,
                key: regionName.of(countryCode) || "",
            };
        })
        .sort((a: any, b: any) => (a.key > b.key ? 1 : -1));

    const powerList =
        locationType === "HOME" ? homePowerList : companyPowerList;

    const handleChange = (e: any) => {
        setAddress({ ...address, [e.target.name]: e.target.value });
    };

    const updateAdressPower = (value: number | null) => {
        setAddress({
            ...address,
            power: value,
        });
    };

    const [updateAddress, { error: updateError }] = useMutation(
        UPDATE_ADDRESS,
        {
            variables: {
                id: id,
                address: address,
            },
            onCompleted: (data) => {
                let { __typename, id, ...cleanedAddress } = data.updateAddress;
                setAddress(cleanedAddress);
                closeModal();
            },
            onError: (error) => {
                console.log("Error updating address: ", error);
            },
        }
    );

    const [createAddress, { error: createError }] = useMutation(
        CREATE_ADDRESS,
        {
            variables: {
                address: { ...address, locationType },
                vehicleId: vehicleId ? vehicleId : null,
            },
            onCompleted: () => {
                closeModal();
            },
            onError: (error) => {
                console.log("Error creating address: ", error);
            },
        }
    );

    const saveAddress = () => {
        setDisplayFormErrors(false);
        if (!isFormValid()) {
            setDisplayFormErrors(true);
            return;
        }
        delete address?.id;
        return locationData?.name ? updateAddress() : createAddress();
    };

    return (
        <ModalWrapper
            isOpen={isOpen}
            close={closeModal}
            className={`w-3/5 ${className}`}
        >
            <>
                <ModalHeader
                    title={
                        locationType == "HOME"
                            ? t("edit-location.home-title")
                            : t("edit-location.company-title")
                    }
                    onCancel={closeModal}
                    isLastStep
                />

                <div className="p-10 max-h-full overflow-y-auto">
                    {setLocationType ? (
                        <LocationTypeToggles
                            locationType={locationType}
                            setLocationType={setLocationType}
                        />
                    ) : null}
                    <Form className="ring-0 p-0">
                        <>
                            <div className="col-span-3">
                                <div className="flex flex-col">
                                    <label className="text-Grey-dark mb-2">
                                        {t("edit-location.name-label")}
                                    </label>
                                    <input
                                        className={`border boder-solid ${displayFormErrors &&
                                                !formValidity["name"]
                                                ? "border-danger-500"
                                                : "border-Grey-tint"
                                            } px-6 h-16 bg-white text-lg rounded`}
                                        name="name"
                                        required
                                        value={address?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-start-1 col-span-5">
                                <div className="flex flex-col">
                                    <label className="text-Grey-dark mb-2">
                                        {t("edit-location.street-label")}
                                    </label>
                                    <input
                                        className={`border boder-solid ${displayFormErrors &&
                                                !formValidity["street"]
                                                ? "border-danger-500"
                                                : "border-Grey-tint"
                                            } px-6 h-16 bg-white text-lg rounded`}
                                        name="street"
                                        required
                                        value={address?.street}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="flex flex-col">
                                    <label className="text-Grey-dark mb-2">
                                        {t("edit-location.number-label")}
                                    </label>
                                    <input
                                        className={`border boder-solid ${displayFormErrors &&
                                                !formValidity["streetNr"]
                                                ? "border-danger-500"
                                                : "border-Grey-tint"
                                            } px-6 h-16 bg-white text-lg rounded`}
                                        name="streetNr"
                                        required
                                        value={address?.streetNr}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div className="grid grid-cols-5 gap-6">
                                    <div className="flex flex-col col-span-1">
                                        <label className="text-Grey-dark mb-2">
                                            {t(
                                                "edit-location.postal-code-label"
                                            )}
                                        </label>
                                        <input
                                            className={`border boder-solid ${displayFormErrors &&
                                                    !formValidity["zip"]
                                                    ? "border-danger-500"
                                                    : "border-Grey-tint"
                                                } px-6 h-16 bg-white text-lg rounded`}
                                            name="zip"
                                            required
                                            value={address?.zip}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex flex-col col-span-2">
                                        <label className="text-Grey-dark mb-2">
                                            {t("edit-location.city-label")}
                                        </label>
                                        <input
                                            className={`border boder-solid ${displayFormErrors &&
                                                    !formValidity["city"]
                                                    ? "border-danger-500"
                                                    : "border-Grey-tint"
                                                } px-6 h-16 bg-white text-lg rounded`}
                                            name="city"
                                            required
                                            value={address?.city}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className=" flex flex-col col-span-2">
                                        <CountrySelector
                                            error={
                                                displayFormErrors &&
                                                !formValidity[
                                                "billingAddressCountry"
                                                ]
                                            }
                                            handleChange={(item: any) => {
                                                console.log(
                                                    "Received item: ",
                                                    item
                                                );
                                                setAddress({
                                                    ...address,
                                                    country: item
                                                        ? item.value
                                                        : null,
                                                });
                                            }}
                                            selectedCountry={
                                                address.country || null
                                            }
                                            countryList={countryList}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <div
                                    className="flex mb-5 items-center"
                                    style={{ zIndex: 2000 }}
                                >
                                    <label className="block text-h5 text-secondary-600 font-normal pl-2 truncate ">
                                        {t("edit-location.speed-label")}
                                    </label>
                                    <Tooltip
                                        content={<div className=" w-56 text-xs">{t("edit-location.speed-tooltip")}</div>}
                                        placement="top-start"
                                    >
                                        {<InformationIcon className="ml-1" />}
                                    </Tooltip>
                                </div>
                                <div className="flex flex-row">
                                    {powerList.map((power) => (
                                        <div className="mr-3.5">
                                            <ChargingPowerSquare
                                                key={power}
                                                value={power}
                                                placeholder={
                                                    power?.toLocaleString(
                                                        localeCode
                                                    ) || "x"
                                                }
                                                addressPower={
                                                    address?.power || null
                                                }
                                                classNames="p-6 rounded w-9 h-9 shadow-dropShadow"
                                                onClick={updateAdressPower}
                                                t={t}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {isDriver || locationType === "WORKPLACE" ? (
                                <DriverEnergyValues
                                    locationType={locationType}
                                    energyPrice={energyPrice}
                                    energyCo2={energyCo2}
                                    setEnergyPrice={setEnergyPrice}
                                    setEnergyCo2={setEnergyCo2}
                                />
                            ) : null}
                        </>
                    </Form>

                    {displayFormErrors ? (
                        <div className="mt-6">
                            <EventBanner
                                state={EventState.ERROR}
                                content={<>{t("input-error")}</>}
                            />
                        </div>
                    ) : null}

                    {(createError || updateError) && (
                        <div className="mt-6">
                            <EventBanner
                                state={EventState.ERROR}
                                content={<>{t("edit-location.error")}</>}
                            />
                        </div>
                    )}
                    <div className="mt-10">
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LARGE}
                            onClick={() => saveAddress()}
                        >
                            {t("edit-location.save-button")}
                        </Button>
                    </div>
                </div>
            </>
        </ModalWrapper>
    );
}
