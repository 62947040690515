import { useTranslation } from "react-i18next";
import { Logo } from "../../../../../../../../common/components/LayoutWrapper/Logo";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import { ImageLayout, IncludedInProductLabel, ProductInformationLayout } from "./components";
import dongleBoxImg from "../../../../../../../../assets/driver_payment/dongle_box.webp";
import dongleAppLayoutImg from "../../../../../../../../assets/driver_payment/app_layout.webp";
import dongleImg from "../../../../../../../../assets/driver_payment/dongle.webp";



export function DriverDongleProductInfo({ price, currencySymbol }: { price: number, currencySymbol: string }) {


    const { t } = useTranslation("driverPaymentSystem");

    const { localeCode } = useLayout();

    console.log("Locale code: ", localeCode);

    return (
        <div className="text-Grey-dark flex flex-col h-full justify-between">
            <ProductInformationLayout
                title={t("products.driverDongle.name")}
                price={price}
                description={t("products.driverDongle.description")}
                currencySymbol={currencySymbol}
                additionalInfo={<div className="flex items-center text-Grey-dark font-bold">
                    <div>{t("products.driverDongle.inStock")}</div>
                    <div className="mx-1">|</div>
                    <div>{t("products.driverDongle.nowAvailable")}</div>
                </div>}
                checkList={<>
                    <IncludedInProductLabel label={t("products.driverDongle.checklist.point1")} />
                    <IncludedInProductLabel label={t("products.driverDongle.checklist.point2")} />
                    <IncludedInProductLabel label={t("products.driverDongle.checklist.point3", {
                        price: 9.90.toLocaleString(localeCode, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }), // TODO dynamically get the price (Search for // ATTENTION : DISCUSS_WITH_NIMA  on TariffPrice repository )
                        currencySymbol
                    })} />
                </>}
            />

            <ImageLayout imageMain={<img src={dongleBoxImg} />} image1={<img className="w-14" src={dongleImg} />} image2={<img src={dongleAppLayoutImg} />} />

        </div>


    )


}